import React from "react"
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import SwiperCore, { Navigation, Pagination, EffectFade } from 'swiper'
import Arrow from '../images/arrow.inline.svg'

import { Trans, useI18next } from 'gatsby-plugin-react-i18next'


import { Swiper, SwiperSlide } from "swiper/react"

// Import Swiper styles
import 'swiper/swiper.min.css'
import 'swiper/components/effect-fade/effect-fade.min.css'

SwiperCore.use([Navigation, Pagination, EffectFade])

const SectDesafios = (props) => {

  const { language } = useI18next()

  const data = useStaticQuery(graphql`
    {
      allWpDesafio(
        sort: { order: ASC, fields: menuOrder }
        filter: { language: { code: { eq: ES } } }
      ) {
        nodes {
          uri
          # date(formatString: "DD MMMM, YYYY")
          title
          id
          infoDesafios {
            pregunta
            descripcion
            textura {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    # aspectRatio: 1
                    width: 700
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
          }
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    # aspectRatio: 1
                    width: 700
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
          }
          language {
            code # Language code
          }
          translations {
            language {
              code
              locale
            }
            title
            infoDesafios {
              pregunta
              descripcion

            }
          }
        }
      }
    }
  `)

  const desafios = data.allWpDesafio.nodes





  return (
    <section className='border-b desafios bg-beige'>
      <header className='border-b'>
        <h2 className='max-w-4xl px-4 py-16 mx-auto text-center lg:py-24 lg:text-4xl'>
          <Trans>
            El programa considera 5 desafíos o áreas que podrán guiarte en la
            postulación, la que puede estar asociada a uno o más de ellos.
          </Trans>
        </h2>
      </header>
      <div className='flex flex-col mx-auto xl:container '>
        <div className='relative slider'>
          <Swiper
            className='grid'
            autoHeight={true}
            loop
            // effect='fade'
            // allowTouchMove={false}
            preventClicks={false}
            // virtualTranslate={true}
            // preventClicksPropagation={false}
            spaceBetween={50}
            slidesPerView={1}
            navigation={{
              nextEl: '.nav-right',
              prevEl: '.nav-left',
            }}
            pagination={{ el: '.page-fraction', type: 'fraction' }}
            watchSlidesProgress
            watchSlidesVisibility
            // onSwiper={(swiper) => {}}
            // onSlideChange={(swiper) => {
            //   let tl = gsap.timeline()
            //   tl.to(['.slide-content', '.slide-image-wrapper'], {
            //     autoAlpha: 0,
            //     duration: 0.2,
            //   })
            //     .set('.swiper-slide-visible .slide-content', {
            //       autoAlpha: 1,
            //       zIndex: 1,
            //     })
            //     .from(
            //       '.swiper-slide-visible .slide-content .slide-content__item',
            //       {
            //         yPercent: 5,
            //         autoAlpha: 0,
            //         stagger: 0.3,
            //         duration: 0.3,
            //         ease: 'power2.inOut',
            //       }
            //     )
            //     .to('.swiper-slide-visible .slide-image-wrapper', {
            //       autoAlpha: 1,
            //       duration: 0.3,
            //     })
            // }}
          >
            <div className='absolute z-10 flex justify-between w-full space-x-2 top-24 swiper-nav lg:hidden'>
              <span className='flex items-center justify-center w-10 h-10 border rounded-full cursor-pointer nav-left'>
                <Arrow className='rotate-180 fill-current w-7 h-7 transform-gpu' />
              </span>
              <span className='flex items-center justify-center w-10 h-10 border rounded-full cursor-pointer nav-right'>
                <Arrow className='fill-current w-7 h-7' />
              </span>
            </div>
            <div className='bottom-0 left-0 hidden w-full grid-cols-2 px-4 mt-6 text-black lg:gap-x-32 gap-y-32 lg:gap-y-0 lg:mt-0 lg:grid lg:absolute swiper-controls__wraper--lg'>
              <div className='z-10 flex items-center justify-between w-full col-span-1 pb-5 swiper-controls'>
                <div className='flex space-x-4 swiper-nav--lg'>
                  <span className='flex items-center justify-center w-16 h-16 border rounded-full cursor-pointer nav-left'>
                    <Arrow className='w-8 h-8 rotate-180 fill-current transform-gpu' />
                  </span>
                  <span className='flex items-center justify-center w-16 h-16 border rounded-full cursor-pointer nav-right'>
                    <Arrow className='w-8 h-8 fill-current' />
                  </span>
                </div>

                <div className='swiper-pages'>
                  <span className='text-4xl font-bold page-fraction'></span>
                </div>
              </div>
            </div>

            {desafios.map((desafio, index) => {

              let title = desafio.title
              let pregunta = desafio.infoDesafios.pregunta
              let descripcion = desafio.infoDesafios.descripcion

              desafio.translations.forEach((trans) => {
                // console.log(trans)
                if (language.toUpperCase() === trans.language.code) {
                  title = trans.title.cargo
                  pregunta = trans.infoDesafios.pregunta
                  descripcion = trans.infoDesafios.descripcion
                }
              })

              return (
                <SwiperSlide
                  key={desafio.id}
                  className='flex flex-col self-center grid-cols-2 lg:divide-x lg:grid swiper-no-swiping '
                >
                  <div className='px-4 pt-16 pr-16 pb-28 slide-content'>
                    <h3 className='mb-4 text-xl font-bold lg:text-3xl slide-content__title'>
                      {title}
                    </h3>

                    <div className='lg:text-xl slide-content__text'>
                      <p
                        className='mb-4 font-bold lg:text-2xl slide-question'
                        dangerouslySetInnerHTML={{
                          __html: pregunta,
                        }}
                      />

                      <div
                        dangerouslySetInnerHTML={{
                          __html: descripcion,
                        }}
                      />
                    </div>
                  </div>

                  <div className='relative grid flex-col items-center justify-center order-first h-60 lg:h-auto lg:order-none slide-image-wrapper'>
                    {desafio.infoDesafios.textura && (
                      <GatsbyImage
                        alt=""
                        loading='eager'
                        placeholder='blurred'
                        style={{
                          gridArea: '1/1',
                          height: '100%',
                          // You can set a maximum height for the image, if you wish.
                          // maxHeight: 600,
                        }}
                        layout='fullWidth'
                        // className="h-full"
                        // objectFit='scale-down'
                        image={getImage(desafio.infoDesafios.textura.localFile)}

                        // className='mb-2'
                      />
                    )}

                    <div
                      style={{
                        // By using the same grid area for both, they are stacked on top of each other
                        gridArea: '1/1',
                        position: 'relative',
                        // This centers the other elements inside the hero component
                        placeItems: 'center',
                        display: 'grid',
                      }}
                    >
                      <GatsbyImage
                        className='w-full max-w-md max-h-40 lg:max-h-full'
                        loading='eager'
                        placeholder='none'
                        layout='fullWidth'
                        objectFit='scale-down'
                        image={getImage(desafio.featuredImage.node.localFile)}
                        alt=""

                        // className='mb-2'
                      />
                    </div>
                  </div>
                </SwiperSlide>
              )
            })}

            <div className='text-center swiper-pages lg:hidden'>
              <span className='text-2xl font-bold page-fraction'></span>
            </div>
          </Swiper>
        </div>
      </div>
    </section>
  )
}

export default SectDesafios