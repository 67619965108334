import React, { useRef, useEffect } from 'react'
import { Trans } from 'gatsby-plugin-react-i18next'

import { gsap } from 'gsap'

import { ScrollTrigger } from 'gsap/ScrollTrigger'

const SectEtapas = (props) => {
  const ref = useRef()

  // const updateScroll = () => isBrowser && window.scroll.update()

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger)
    const el = ref.current
    ScrollTrigger.matchMedia({
      '(min-width: 800px)': function () {
        const tl = gsap.timeline({
          scrollTrigger: {
            // scroller: "#scroll-container",
            trigger: el,
            // scrub: true,
            // pin: true,
            start: 'center center+=70px',
            end: '+=100%',
            // onUpdate: ({ progress }) => {
            //   console.log(tl.progress());
            //   if (tl.progress() < progress) {
            //     tl.progress(progress)
            //   } else {
            //     null
            //   }
            //   // tl.progress() < progress ?  : null
            // }

            // markers: true,
          },
        })

        tl.from(el.querySelector('.ruta'), {
          duration: 1,
          // drawSVG: 0,
          autoAlpha: 0,
          ease: 'none',
        })
          .from(
            el.querySelectorAll('.c'),
            {
              duration: 0.25,
              autoAlpha: 0,
              scale: 0,
              transformOrigin: 'center center',
              stagger: 0.15,
              ease: 'none',
            },
            '>-1'
          )
          .from(
            el.querySelectorAll('.t'),
            {
              duration: 0.25,
              autoAlpha: 0,
              scale: 0,
              transformOrigin: 'center center',
              stagger: 0.15,
              ease: 'none',
            },
            '>-1'
          )
          .to({}, { duration: 1.5 })
          // .call(() => updateScroll())
      },
    })

    // ScrollTrigger.getAll().forEach((t) => t.kill())
  }, [])

  return (
    <section className='py-16 lg:py-28 section-etapas' {...props}>
      <div className='container px-4 mx-auto' ref={ref}>
        <h2 className='mb-8 text-sm font-semibold underline uppercase lg:text-lg'>
          <Trans>Hoja de Ruta</Trans>
        </h2>
        <h3 className='max-w-lg pr-8 mb-8 text-2xl font-bold lg:text-5xl ruta__trigger'>
          <Trans>Etapas del Programa de Innovación Abierta</Trans>
        </h3>

        <div className='relative ruta--line'>
          <svg
            id='svg'
            className='hidden thesvg lg:block'
            viewBox='0 0 1400 370'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              className='ruta'
              d='M19 275C201.203 275 462.608 275 570.873 275C598.487 275 620.76 252.614 620.76 225V69C620.76 41.3858 643.146 19 670.76 19H1184.5'
              stroke='black'
              strokeWidth='20'
              strokeLinecap='round'
              strokeLinejoin='round'
            />

            <circle
              className='c c01'
              cx='19'
              cy='276'
              r='15'
              fill='white'
              stroke='black'
              strokeWidth='8'
            />
            <foreignObject
              width='160'
              height='56'
              x='0'
              y='310'
              className='t t01'
            >
              <h4 className='leading-tight '>
                <Trans>Inicio Convocatoria</Trans>
                <br />
                <span className='font-bold '>
                  <Trans>29/06</Trans>
                </span>
              </h4>
            </foreignObject>

            <circle
              className='c c02'
              cx='276'
              cy='276'
              r='15'
              fill='white'
              stroke='black'
              strokeWidth='8'
            />
            <foreignObject
              width='160'
              height='56'
              x='200'
              y='310'
              className='t t01'
            >
              <h4 className='leading-tight text-center '>
                <Trans>Cierre Convocatoria</Trans>
                <br />
                <span className='font-bold '>
                  <Trans>31/08</Trans>
                </span>
              </h4>
            </foreignObject>

            <circle
              className='c c03'
              cx='520'
              cy='276'
              r='15'
              fill='white'
              stroke='black'
              strokeWidth='8'
            />
            <foreignObject
              width='160'
              height='56'
              x='440'
              y='310'
              className='t t01'
            >
              <h4 className='leading-tight text-center '>
                <Trans>Demo day</Trans>
                <br />
                <span className='font-bold '>
                  <Trans>07/09</Trans>
                </span>
              </h4>
            </foreignObject>

            <circle
              className='c c04'
              cx='702'
              cy='19'
              r='15'
              fill='white'
              stroke='black'
              strokeWidth='8'
            />
            <foreignObject
              width='160'
              height='56'
              x='625'
              y='55'
              className='t t01'
            >
              <h4 className='leading-tight text-center '>
                <Trans>Aceleración</Trans> <br />
                <span className='font-bold '>
                  <Trans>27/09 al 11/10</Trans>
                </span>
              </h4>
            </foreignObject>

            <circle
              className='c c05'
              cx='961'
              cy='19'
              r='15'
              fill='white'
              stroke='black'
              strokeWidth='8'
            />
            <foreignObject
              width='160'
              height='56'
              x='880'
              y='55'
              className='t t01'
            >
              <h4 className='leading-tight text-center '>
                <Trans>Hito Presentación</Trans>
                <br />
                <span className='font-bold '>
                  <Trans>18/10</Trans>
                </span>
              </h4>
            </foreignObject>

            <circle
              className='c c06'
              cx='1188'
              cy='19'
              r='15'
              fill='white'
              stroke='black'
              strokeWidth='8'
            />
            <foreignObject
              width='160'
              height='56'
              x='1110'
              y='55'
              className='t t01'
            >
              <h4 className='leading-tight text-center '>
                <Trans>Post Aceleración</Trans>
                <br />
                <span className='font-bold '>
                  <Trans>01/11 en adelante</Trans>
                </span>
              </h4>
            </foreignObject>
          </svg>

          {/* <a
            className='bottom-0 right-0 items-center justify-center hidden w-full px-6 py-3 mb-10 text-2xl font-semibold text-white uppercase border-none rounded-full lg:flex lg:absolute lg:max-w-xs button-noticias bg-orange'
            href='#/'
          >
            Postula
          </a> */}
        </div>

        <div className='mb-14 ruta--line-mobile lg:hidden'>
          <ul className='relative flex flex-col ml-4 space-y-6 border-l-8'>
            <li className='flex'>
              <span
                className='flex-none mr-6 bg-white border-8 rounded-full dot'
                style={{ width: 30, height: 30, marginLeft: -19 }}
              ></span>
              <div className='flex flex-col'>
                <Trans>Inicio Convocatoria</Trans>
                <br />
                <span className='flex-none text-sm font-bold'>
                  <Trans>29/06</Trans>
                </span>
              </div>
            </li>
            <li className='flex'>
              <span
                className='flex-none mr-6 bg-white border-8 rounded-full dot'
                style={{ width: 30, height: 30, marginLeft: -19 }}
              ></span>

              <div className='flex flex-col'>
                <Trans>Cierre Convocatoria</Trans>
                <br />
                <span className='flex-none text-sm font-bold'>
                  <Trans>31/08</Trans>
                </span>
              </div>
            </li>
            <li className='flex'>
              <span
                className='flex-none mr-6 bg-white border-8 rounded-full dot'
                style={{ width: 30, height: 30, marginLeft: -19 }}
              ></span>

              <div className='flex flex-col'>
                <Trans>Demo day</Trans>
                <br />
                <span className='flex-none text-sm font-bold'>
                  <Trans>07/09</Trans>
                </span>
              </div>
            </li>
            <li className='flex'>
              <span
                className='flex-none mr-6 bg-white border-8 rounded-full dot'
                style={{ width: 30, height: 30, marginLeft: -19 }}
              ></span>
              <div className='flex flex-col'>
                <Trans>Aceleración</Trans>
                <br />
                <span className='flex-none text-sm font-bold'>
                  <Trans>27/09 al 11/10</Trans>
                </span>
              </div>
            </li>
            <li className='flex'>
              <span
                className='flex-none mr-6 bg-white border-8 rounded-full dot'
                style={{ width: 30, height: 30, marginLeft: -19 }}
              ></span>

              <div className='flex flex-col'>
                <Trans>Hito Presentación</Trans>
                <br />
                <span className='flex-none text-sm font-bold'>
                  <Trans>18/10</Trans>
                </span>
              </div>
            </li>
            <li className='flex'>
              <span
                className='flex-none mt-4 mr-6 bg-white border-8 rounded-full dot'
                style={{ width: 30, height: 30, marginLeft: -19 }}
              ></span>
              <div className='flex flex-col'>
                <Trans>Post Aceleración</Trans>
                <br />
                <span className='flex-none text-sm font-bold'>
                  <Trans>01/11 en adelante</Trans>
                </span>
              </div>
            </li>
          </ul>
        </div>

        {/* <Link
          className='block w-full max-w-xs px-6 py-2 mx-auto text-2xl font-semibold text-center uppercase bg-black border border-black rounded-full text-green hover:bg-green hover:text-black '
          to='/about/'
        >
          Conoce más
        </Link> */}

        {/* <a
          className='bottom-0 right-0 flex items-center justify-center w-full px-6 py-3 text-2xl font-semibold text-white uppercase border-none rounded-full lg:max-w-xs button-noticias bg-orange lg:hidden'
          href='#/'
        >
          Postula
        </a> */}
      </div>
    </section>
  )
}

export default SectEtapas
