import React from "react"

import Collapsible from '../components/collapsible'
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next'



const SectPorQue = () => {
  const { t } = useTranslation()

  return (
    <section className='flex text-white bg-black por-que '>
      <div className='flex flex-col justify-between px-4 mx-auto leading-normal text-left lg:flex-row xl:container'>
        <div className='py-16 lg:border-r lg:w-1/2 border-green lg:py-28 lg:pr-24'>
          <h2 className='mb-8 underline uppercase text-green lg:text-lg'>
            <Trans>¿Por qué postular a este concurso?</Trans>
          </h2>

          <p className='text-3xl font-extrabold lg:mb-20 lg:text-5xl'>
            <Trans i18nKey='whyTextLong'>
              Tenemos más de USD 200.000 para invertir <strong className='text-green'>entre los proyectos seleccionados para entrar a la fase de aceleración y post‑aceleración</strong>.
            </Trans>
          </p>

          <div className='flex-col items-center hidden lg:flex lg:flex-row'>
            <p className='mb-4 mr-4 text-2xl font-bold uppercase text-green lg:mb-0'>
              <Trans>¿Preguntas?</Trans>
            </p>
            <Link
              className='items-center justify-center px-6 py-3 text-2xl font-bold text-black uppercase border-none rounded-full lg:inline-flex button-noticias bg-green'
              to='/faq/'
            >
              <Trans>Revisa las Q&A</Trans>
            </Link>
          </div>
        </div>

        <div className='block lg:hidden'>
          <Collapsible
            className={`mb-6 text-left focus:outline-none bg-none rotate text-2xl font-bold text-green`}
            title={t('Aporte al planeta')}
          >
            <p className='text-base font-normal text-white'>
              <Trans>
                Contribuirás a enfrentar desafíos globales como el cambio
                climático, desarrollando las soluciones de envasado del futuro.
              </Trans>
            </p>
          </Collapsible>

          <Collapsible
            className={`mb-6 text-left focus:outline-none bg-none rotate text-2xl font-bold text-green`}
            title={t('Aceleración y Post-Aceleración')}
          >
            <p className='text-base font-normal text-white'>
              <Trans>
                Los equipos seleccionados podrían transformar sus soluciones en
                prototipos que agregan valor a la red de CMPC, con opciones
                reales de implementación en el mercado Latinoamericano y
                mundial.
              </Trans>
            </p>
          </Collapsible>

          <Collapsible
            className={`mb-6 text-left focus:outline-none bg-none rotate text-2xl font-bold text-green`}
            title={t('Acceso a red de actores y empresas CMPC')}
          >
            <p className='text-base font-normal text-white'>
              <Trans>
                Accederás a una red comercial y de supply chain que podría
                facilitar el product-market fit, la escalabilidad y la
                comercialización de la solución que propongan.
              </Trans>
            </p>
          </Collapsible>

          <Collapsible
            className={`mb-6 text-left focus:outline-none bg-none rotate text-2xl font-bold text-green`}
            title={t('Validación')}
          >
            <p className='text-base font-normal text-white'>
              <Trans>
                Las soluciones finalistas podrían llegar a validar sus
                soluciones en una cadena de valor real.
              </Trans>
            </p>
          </Collapsible>

          <Collapsible
            className={`mb-16 text-left focus:outline-none bg-none rotate text-2xl font-bold text-green`}
            title={t('Aprendizaje')}
          >
            <p className='text-base font-normal text-white'>
              <Trans>
                Serás parte de un proceso de aprendizaje con líderes nacionales
                e internacionales en packaging, innovación y sustentabilidad.
              </Trans>
            </p>
          </Collapsible>

          <div className='flex-col items-center hidden lg:flex lg:flex-row'>
            <p className='mb-4 mr-4 text-2xl font-bold uppercase text-green lg:mb-0'>
              <Trans>¿Preguntas?</Trans>
            </p>
            <Link
              className='items-center justify-center px-6 py-3 text-2xl font-bold text-black uppercase border-none rounded-full lg:inline-flex button-noticias bg-green'
              to='/faq/'
            >
              <Trans>Revisa las Q&A</Trans>
            </Link>
          </div>
        </div>

        <div className='hidden w-1/2 lg:block py-28 lg:pl-24'>
          <div className='flex flex-col space-y-8'>
            <div>
              <h4 className='text-2xl font-bold text-left focus:outline-none bg-none rotate text-green'>
                <Trans>Aporte al planeta</Trans>
              </h4>
              <p className='text-base font-normal text-white'>
                <Trans>
                  Contribuirás a enfrentar desafíos globales como el cambio
                  climático, desarrollando las soluciones de envasado del
                  futuro.
                </Trans>
              </p>
            </div>
            <div>
              <h4 className='text-2xl font-bold text-left focus:outline-none bg-none rotate text-green'>
                <Trans>Aceleración y Post-Aceleración</Trans>
              </h4>
              <p className='text-base font-normal text-white'>
                <Trans>
                  Los equipos seleccionados podrían transformar sus soluciones
                  en prototipos que agregan valor a la red de CMPC, con opciones
                  reales de implementación en el mercado Latinoamericano y
                  mundial.
                </Trans>
              </p>
            </div>
            <div>
              <h4 className='text-2xl font-bold text-left focus:outline-none bg-none rotate text-green'>
                <Trans>Acceso a red de actores y empresas CMPC</Trans>
              </h4>
              <p className='text-base font-normal text-white'>
                <Trans>
                  Accederás a una red comercial y de supply chain que podría
                  facilitar el product-market fit, la escalabilidad y la
                  comercialización de la solución que propongan.
                </Trans>
              </p>
            </div>
            <div>
              <h4 className='text-2xl font-bold text-left focus:outline-none bg-none rotate text-green'>
                <Trans>Validación</Trans>
              </h4>
              <p className='text-base font-normal text-white'>
                <Trans>
                  Las soluciones finalistas podrían llegar a validar sus
                  soluciones en una cadena de valor real.
                </Trans>
              </p>
            </div>
            <div>
              <h4 className='text-2xl font-bold text-left focus:outline-none bg-none rotate text-green'>
                <Trans>Aprendizaje</Trans>
              </h4>
              <p className='text-base font-normal text-white'>
                <Trans>
                  Serás parte de un proceso de aprendizaje con líderes
                  nacionales e internacionales en packaging, innovación y
                  sustentabilidad.
                </Trans>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SectPorQue